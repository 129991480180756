import React from "react";
import Layout from "../Layouts/Layout";
import { ContentCard } from "../components/ContentCard";
import { BodyText, Heading } from "../components/Typography";
import { StyledLinkExternal } from "../components/StyledLink";
import styled from "styled-components";
import { Astronaut } from "../components/Astronaut";

const home = () => (
  <>
    <Layout>
      <PageContent>
        <StyledAstronaut />
        <ContentCard>
          <Heading>Hi there</Heading>
          <BodyText>I{"'"}m Jo, a web developer from Yorkshire.</BodyText>
          <BodyText>
            There{"'"}s a lot of empty space here at the moment but I have
            plans...
          </BodyText>
          <BodyText>
            In the meantime you could play{" "}
            <StyledLinkExternal href={"https://jo-game-of-life.netlify.app/"}>
              my version of Conway{"'"}s Game of Life.
            </StyledLinkExternal>
          </BodyText>
        </ContentCard>
      </PageContent>
    </Layout>
  </>
);

export default home;

const PageContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.5fr) minmax(0, 2fr);
  grid-template-rows: minmax(auto, 1fr);
  ${({
    theme: {
      breakpoints: { down, values },
    },
  }) => down(values.sm)} {
    grid-template-columns: 1fr;
  }
`;

const StyledAstronaut = styled(Astronaut)`
  justify-self: end;
`;
