import React, { useEffect, useRef } from "react";
import { gsap, Sine } from "gsap";

export const Astronaut = ({ className }) => {
  const ref = useRef();
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });
    tl.to(ref.current, 2, {
      fill: "#e3e6e9",
      ease: Sine.easeOut,
    });
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="200"
      viewBox="0 0 512 512"
      className={className}
    >
      <path fill="#e3e6e9" d="M136 232h16v48h-16zM360 232h16v48h-16z" />
      <path
        d="M320 144h-24v16a31.758 31.758 0 01-1.696 10.276 31.577 31.577 0 01-1.109 2.821c-.319.71-.656 1.41-1.024 2.091-.076.14-.157.276-.234.415a32.062 32.062 0 01-3.639 5.208 32.271 32.271 0 01-1.671 1.817c-.018.018-.037.034-.054.052a32.025 32.025 0 01-3.937 3.326 32.01 32.01 0 01-6.045 3.419c-.212.091-.428.175-.643.261A31.843 31.843 0 01264 192h-16c-17.673 0-32-14.327-32-32v-16h-24c-30.875 0-56 25.125-56 56v16h16c0-8.837 7.164-16 16-16h8v80h16c0-4.422 3.578-8 8-8s8 3.578 8 8h16c0-4.422 3.578-8 8-8s8 3.578 8 8h96v-80h8c8.836 0 16 7.163 16 16h16v-16c0-30.875-25.125-56-56-56zm-24 96h-16c-4.422 0-8-3.578-8-8s3.578-8 8-8h16c4.422 0 8 3.578 8 8s-3.578 8-8 8zm0-24h-16c-4.422 0-8-3.578-8-8s3.578-8 8-8h16c4.422 0 8 3.578 8 8s-3.578 8-8 8zM360 331.542c-6.623 5.97-14.835 10.164-24 11.713V312h-96v8c0 4.422-3.578 8-8 8s-8-3.578-8-8v-8h-16v8c0 4.422-3.578 8-8 8s-8-3.578-8-8v-8h-16v31.255c-9.165-1.549-17.377-5.744-24-11.713V296h-16c0 24.047 17.773 44.016 40.875 47.469l14.19 113.545A40.098 40.098 0 01200 456h40a8 8 0 018 8v-96c0-4.414 3.586-8 8-8s8 3.586 8 8v96a8 8 0 018-8h40c3.073 0 6.06.358 8.935 1.014l14.19-113.545C358.226 340.015 376 320.047 376 296h-16v35.542z"
        fill="#e3e6e9"
      />
      <path
        d="M344 304c0 .276-.014.549-.041.818a7.797 7.797 0 01-.223 1.189c-.05.193-.104.383-.168.569a8.01 8.01 0 01-4.92 4.966A7.959 7.959 0 01336 312v31.255c9.165-1.549 17.377-5.744 24-11.713V296h-16v8zM344 200h-8v16h24c0-8.837-7.164-16-16-16zM336 232h24v48h-24zM168 304v-8h-16v35.542c6.623 5.97 14.835 10.164 24 11.713V312a8 8 0 01-8-8zM152 232h24v48h-24zM176 200h-8c-8.836 0-16 7.163-16 16h24v-16z"
        fill="#b6b8be"
      />
      <path
        d="M200 120h56V0c-44.109 0-80 35.891-80 80 0 15.898 4.656 31.266 13.477 44.445a7.994 7.994 0 002.609 2.443c.542-3.89 3.874-6.888 7.914-6.888z"
        fill="#ff9300"
      />
      <path
        d="M312 120c4.042 0 7.374 3 7.915 6.893a7.974 7.974 0 002.609-2.44C331.336 111.281 336 95.906 336 80c0-44.109-35.891-80-80-80v120h56z"
        fill="#ffcf00"
      />
      <path
        d="M224 136h32v-16h-56c-4.04 0-7.372 2.998-7.914 6.889A7.98 7.98 0 00192 128v16h24a8 8 0 018-8z"
        fill="#888693"
      />
      <path
        ref={ref}
        d="M296 200h-16c-4.422 0-8 3.578-8 8s3.578 8 8 8h16c4.422 0 8-3.578 8-8s-3.578-8-8-8zM296 224h-16c-4.422 0-8 3.578-8 8s3.578 8 8 8h16c4.422 0 8-3.578 8-8s-3.578-8-8-8z"
        fill="#ff4f19"
      />
      <path
        d="M312 120h-56v16h32a8 8 0 018 8h24v-16c0-.377-.035-.744-.085-1.107C319.374 123 316.042 120 312 120z"
        fill="#b6b8be"
      />
      <path
        d="M240 152v-16h-16a8 8 0 00-8 8v16c0 17.673 14.327 32 32 32h8v-24c-8.837 0-16-7.164-16-16zM296 160zM276.592 189.424c-.212.091-.428.175-.643.261.214-.086.43-.17.643-.261zM284.534 184.537zM295.835 163.272zM280.244 187.568zM289.236 179.664zM293.858 171.51zM292.171 175.188c-.076.14-.157.276-.234.415.077-.139.159-.276.234-.415zM286.627 182.627c-.018.018-.037.034-.054.052.018-.018.037-.034.054-.052z"
        fill="#5c546a"
      />
      <path
        d="M288 136h-16v16c0 8.837-7.163 16-16 16v24h8a31.843 31.843 0 0011.949-2.315c.215-.086.431-.17.643-.261.755-.324 1.493-.68 2.216-1.058a32.218 32.218 0 003.829-2.361 32.096 32.096 0 003.937-3.326c.018-.018.037-.034.054-.052a32.271 32.271 0 001.671-1.817 32.306 32.306 0 003.639-5.208c.078-.139.159-.275.234-.415.368-.681.705-1.381 1.024-2.091.234-.522.456-1.051.663-1.587a31.828 31.828 0 002.142-11.51v-16A8.001 8.001 0 00288 136z"
        fill="#888693"
      />
      <path d="M256 168c8.837 0 16-7.163 16-16v-16h-16v32z" fill="#ff4f19" />
      <path
        d="M240 152c0 8.837 7.163 16 16 16v-32h-16v16zM336 216h24v16h-24zM152 216h24v16h-24z"
        fill="#e5001e"
      />
      <path fill="#ff4f19" d="M360 216h16v16h-16zM136 216h16v16h-16z" />
      <path d="M152 296h16v8a8 8 0 008 8h16v-32h-40v16z" fill="#5c546a" />
      <path fill="#888693" d="M136 280h16v16h-16z" />
      <path d="M344 288v8h16v-16h-24a8 8 0 018 8z" fill="#5c546a" />
      <path fill="#888693" d="M360 280h16v16h-16z" />
      <path
        fill="#5c546a"
        d="M208 280h16v32h-16zM240 280h16v32h-16zM343.568 306.576zM343.736 306.006z"
      />
      <path
        d="M336 312a7.98 7.98 0 002.648-.458 8.01 8.01 0 004.92-4.966c.064-.187.118-.377.168-.569.1-.387.181-.781.223-1.189A8.19 8.19 0 00344 304v-16a8 8 0 00-8-8h-80v32h80z"
        fill="#888693"
      />
      <path
        d="M192 320c0 4.422 3.578 8 8 8s8-3.578 8-8v-40c0-4.422-3.578-8-8-8s-8 3.578-8 8v40z"
        fill="#ff9300"
      />
      <path
        d="M224 320c0 4.422 3.578 8 8 8s8-3.578 8-8v-40c0-4.422-3.578-8-8-8s-8 3.578-8 8v40z"
        fill="#e5001e"
      />
      <path
        d="M160.432 506.576zM247.959 504.818zM160.264 506.006zM242.648 511.542zM240 456h-40c-3.073 0-6.06.358-8.935 1.014C173.277 461.074 160 476.981 160 496c0-2.74.281-5.415.806-8H248v-24a8 8 0 00-8-8zM264.264 506.006zM346.648 511.542zM351.958 504.818zM264.432 506.576zM272 456a8 8 0 00-8 8v24h87.194a40.16 40.16 0 01.806 8c0-19.018-13.277-34.925-31.065-38.986A40.105 40.105 0 00312 456h-40z"
        fill="#888693"
      />
      <path
        d="M160 496v8c0 .276.014.549.041.818.041.407.122.802.223 1.189.05.193.104.383.168.569a8.01 8.01 0 004.92 4.966c.83.292 1.719.458 2.648.458h72a7.98 7.98 0 002.648-.458 8.01 8.01 0 004.92-4.966c.064-.187.118-.377.168-.569.1-.387.181-.781.223-1.189A8.19 8.19 0 00248 504v-16h-87.194a40.16 40.16 0 00-.806 8zM264 488v16c0 .276.014.549.041.818.041.407.122.802.223 1.189.05.193.104.383.168.569a8.01 8.01 0 004.92 4.966c.83.292 1.719.458 2.648.458h72a7.98 7.98 0 002.648-.458 8.01 8.01 0 004.92-4.966c.064-.187.118-.377.168-.569.1-.387.181-.781.223-1.189A8.19 8.19 0 00352 504v-8c0-2.74-.281-5.415-.806-8H264z"
        fill="#b6b8be"
      />
    </svg>
  );
};
